import React from "react";
import "./staffingServices.scss";
import HeadingImage from "../../../global/headingImage/HeadingImage";
import ServicesCommonPage from "../../../global/servicesCommonPage/ServicesCommonPage";

const StaffingServices = () => {
  return (
    <div className="staffing-service" style={{ width: "90%", margin: "auto" }}>
      <HeadingImage name={"Staffing Services"} />
      <ServicesCommonPage />
    </div>
  );
};

export default StaffingServices;
