import React from "react";
import "./applicationDevelopment.scss";
import HeadingImage from "../../../global/headingImage/HeadingImage";
import ServicesCommonPage from "../../../global/servicesCommonPage/ServicesCommonPage";

const ApplicationDevelopment = () => {
  return (
    <div
      className="application-development"
      style={{ width: "90%", margin: "auto" }}
    >
      <HeadingImage name={"Application Development"} />
      <ServicesCommonPage />
    </div>
  );
};

export default ApplicationDevelopment;
