import React from "react";
import "./servicesCommonPage.scss";
import ServicesLinks from "../servicesLinks/ServicesLinks";
import { useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const talendData = [
  {
    name: "Talent Development",
    description:
      "Glenysys Technologies is committed to developing results that secure a competitive edge that demands innovations. We understand that business needs to get forward with building capabilities through people that shows the importance of development and performance of the company.",
  },
  {
    name: "Talent Strategy",
    description:
      "We provide a comprehensive strategy that aligns skilled people to help your organization to develop wider growth opportunities. Our company has an excellent track record of helping the organization to facilitate customized and measurable options that enhance the overall organization effectiveness to build a strong future success of the company.",
  },
  {
    name: "Integrated Process",
    description:
      "Our talend development is designed to attract, develop, motivate and retain employees who are productive and engaged. The main goal of talent development is to ensure that a high-performance address strategic operational goals and objectives. We have ways of improvising talent development and planning that matters the most to the employees. Our focus is more on the practical approach in finding the right talent that amplifies the growth of the individual career, which can be an asset to the organization.",
  },
  {
    name: "Talent Assessment",
    description:
      "To understand the right talent, a broad assessment of the employee is necessary. We often conduct a series of assessment programs that assist in gauging the skills and workplace strategies.",
  },
  {
    name: "Executive Coaching and Mentoring",
    description:
      "Coaching & mentoring the individualized talent development process that builds one’s capability to achieve goals and maximize organizational performance. Leadership Development Coaching, Business Impact Coaching, and Talent Development Coaching are the key talent engagements designed to the transformation of the business. Our customized talent development services aligned with your current and future goals helps in aspiring key performance challenges that build strength.",
  },
];

const allServicesContent = [
  {
    mainContent: {
      heading: "IT Services",
      description:
        "Glenysys Technologies IT Services are provided with innovative strategies that accelerate the way to do a business of any type that allows enabling technologies stimulating the business growth of your company. From accumulating ideas through our extensive research, analytics and clientele engagement to deliver IT Services and Solutions, we plan meticulously to offer customized, optimized and proficiency with comprehensive in advanced technologies as well as proven IT expertise in the entire IT space. At Glenysys Technologies, we strategically plan to cut the unwanted expenditure for our clients. This will help you in maximizing quality, improves performance, efficiency and procure valuable investments. We use our insights, knowledge, and skills to deliver IT services to meet your expectations to achieve the highest level of your business by leveraging the use of current technology resources and emerging IT trends. We deliver robust, reliable and secure IT infrastructure along with solutions and support services, applications and security services and cloud-based applications in which your entire business relies upon. With our vast industry-specific insights we help our customers to enhance their business operations and transform their goals with reliable innovative driven IT strategies that fuel your company’s growth and ROI.",
    },
    subContentHeading: [
      {
        heading: "Our IT Services includes:",
        description:
          "We specialize in providing precision solutions reflecting key competence in the full spectrum of IT Services including:",
        points: [
          "Application Development",
          "Staffing Services",
          "Big Data Development",
          "Salesforce Development",
          "Talend Development",
        ],
      },
    ],
  },
  {
    mainContent: {
      heading: "Application Development",
      description:
        "Keep your business on-par with changing trends of emerging applications and technologies that highlight your competitive advantage. Glenysys Technologies offers the best application development services at varying levels that can meet your demands. We dedicated ourselves in Application Development and Management solutions and resources to design, develop, integrate, deploy, maintain and support the entire ecosystem of application systems. Our well-qualified experts help in a smart way for companies by serving with continuous improvements that bring secure, reliable and scalable applications that help organizations to achieve maximum efficiency and productivity in the entire Software Development Life Cycle.",
    },
    subContentHeading: [
      {
        heading: "Company’s Advantage",
        points: [
          "Connect, interact and engage to generate a value based customized applications",
          "Improvise application performance and stability",
          "Application Integration and Migration",
          "Adding value to customer creating 100% satisfaction",
          "Improvise development quality that reduces bugs and defects",
          "Enabling rapid technology development resources to allocate for business-critical needs",
          "Thoroughly understand the business domain to create a technology roadmap",
        ],
      },
      {
        heading: "Company’s Advantage",
        points: [
          "Connect, interact and engage to generate a value based customized applications",
          "Improvise application performance and stability",
          "Application Integration and Migration",
          "Adding value to customer creating 100% satisfaction",
          "Improvise development quality that reduces bugs and defects",
          "Enabling rapid technology development resources to allocate for business-critical needs",
          "Thoroughly understand the business domain to create a technology roadmap",
        ],
      },
    ],
    paragraphOne:
      "Applications are pivotal in the business world and it needs to be updated time-to-time in order to procure technologies that create an agile methodological approach. Being a top application development services, we analyze various projects and elaborates blueprints for the application.",
  },
  {
    mainContent: {
      heading: "Big Data Development",
      description:
        "Take a leap of your business that measures productive analysis with the help of Big Data. Big Data now days have become vital for organizations. Day-by-day the world is speeding to digitalized zone and the complexity of the data rises due to large volumes. With this, organizations need to figure out the strategy that could accelerate the analytics of the data that significantly requires to manage a huge volume of data in a simple way. Gelnysys Technologies offers open source technologies solutions like Big Data to take advantage of opportunities to streamline massive and complex data in a simple way. We assist companies in integrating Big Data into their IT setup environment, where we build customize big data solutions.",
    },
    subContentHeading: [
      {
        heading: "Why hire Glenysys for Big Data Development Services?",
        points: [
          "A perfect partner for clients seeking for productivity and efficiency",
          "Our team has the fullest potentials in providing a competent platform for big data solutions.",
          "Highly capable of using advanced strategies in big data and making the best use at the organization level",
          "It's not just excellent but experienced in technical knowledge and skills required for building high-quality code for big data platform.",
          "We are a technology partner and provide complete transparency at every stage of the work.",
          "Our technical data scientists are in expertise carefully examines data from various sources and deliver the best insights for better decision making process.",
          "We provide predictive and descriptive analytics services that allow the organization to make the best use of the datasets.",
        ],
      },
    ],
    paragraphOne:
      "We help business design, architect and implement Big Data services that integrate with the data to derive a meaningful insight without any data discrepancy or loss of data. In every big data project we work, we streamline validation and filtration for scalable data processing and storage to build a strong-algorithms to protect data in the real-time and perform testing.",
  },
];

const salesforceDevelopment = {
  mainHeading: {
    heading: "Salesforce Development",
    description:
      "Glenysys Technologies have got experts who can able to provide a logical way of rendering end-to-end Salesforce development services to carry on responsibilities to customize as per your business needs. The foremost thing about the organization is to understand customer success and customer relationship management (CRM) for business trade. Salesforce – cloud computing service as a software (SaaS) has become a number 1 CRM platform for startups to enterprises. We help organizations in planning, designing, and implementing customizable Salesforce Development Services that transforms the entire business, the way to interact and manage customers, partners, products, and employees. Our company has an excellent track record of blending innovations by making a customizable solution to provide better CRM services of cloud computing highly affordable services. We at Glenysys Technologies is very strong in in-depth researching that can help your business to unleash the full scope that sets beyond the standard functionality of the entire Salesforce Development Services that includes:",
  },
  points: [
    "Integration of CRM Salesforce that suits your business requirements expectations",
    "Implementation of platforms – sales cloud, service cloud, marketing clout, and force.com",
    "Focus on expanding functionality for Salesforce CRM solution by developing applications using various options like the Force, Visualforce, Apex, etc.",
    "Salesforce based mobile apps stimulate business productivity and ROI",
    "Salesforce Lighting Service is a user interface that includes technologies to accelerate to build responsive applications.",
  ],
  subHeading: {
    heading: "Salesforce Implementation Solutions",
    description:
      "Our team of experts helps organizations to build a strategic plan for creating whole new opportunities for helping to implement Salesforce to expand the business processes in the market. We are dedicated to finding the right Salesforce solutions to our clients. We are highly experienced that have deep insights in analyzing your business need to design and develop the most effective Salesforce solutions that are best for your business requirements.",
  },
};

const staffing = {
  mainHeading: {
    heading: "Salesforce Development",
    description:
      "Glenysys Technologies have got experts who can able to provide a logical way of rendering end-to-end Salesforce development services to carry on responsibilities to customize as per your business needs. The foremost thing about the organization is to understand customer success and customer relationship management (CRM) for business trade. Salesforce – cloud computing service as a software (SaaS) has become a number 1 CRM platform for startups to enterprises. We help organizations in planning, designing, and implementing customizable Salesforce Development Services that transforms the entire business, the way to interact and manage customers, partners, products, and employees. Our company has an excellent track record of blending innovations by making a customizable solution to provide better CRM services of cloud computing highly affordable services. We at Glenysys Technologies is very strong in in-depth researching that can help your business to unleash the full scope that sets beyond the standard functionality of the entire Salesforce Development Services that includes:",
  },
  subHeading: {
    heading: "Salesforce Implementation Solutions",
    description:
      "Our team of experts helps organizations to build a strategic plan for creating whole new opportunities for helping to implement Salesforce to expand the business processes in the market. We are dedicated to finding the right Salesforce solutions to our clients. We are highly experienced that have deep insights in analyzing your business need to design and develop the most effective Salesforce solutions that are best for your business requirements.",
  },
  pointsObject: {
    heading:
      "Our End-To-End Staffing Process Helps You To Find Most Desirable Candidates",
    points: [
      {
        mainPoint: "Analyzing Manpower Request",
        subPoints: [
          "Understanding the manpower requirements of the organization",
          "Assessing and Planning based on workforce analysis",
          "Evaluating manpower workforce – measuring existing employees and employees to be intake",
          "Type of staffing requirements – contractual hiring, permanent hiring or contract-to-hire",
        ],
      },
      {
        mainPoint: "Initiating Recruitment Process",
        subPoints: [
          "Starting the recruitment process of searching prospective employees from various sources including job sites, online networks, and references.",
          "Evaluate and validate the source of information",
          "Invite applications of the candidates for the interview process",
        ],
      },
      {
        mainPoint: "Identifying Candidates & Screening Profiles",
        subPoints: [
          "Find qualified professional candidates profile with the clientele's staffing requirements",
          "Send emails to ask candidates to their job preferences, preferred location & expected salary",
          "Check inbox replies of the candidates",
          "Thoroughly conduct reference checks, background, and education verification",
        ],
      },
      {
        mainPoint: "Short-listing of Candidates",
        subPoints: [
          "Share candidates list with clients for short-listing",
          "Short-listed candidates are interviewed",
          "Inform candidates about the status of the interview for the next round",
          "Schedule for a face-to-face interview and final round of the interview",
        ],
      },
      {
        mainPoint: "Selection of Candidates",
        subPoints: [
          "Inform candidates about the selection – via email or a call Candidates need to furnish the required documents",
          "Releasing of Appointment Letter & Offer Letter",
        ],
      },
    ],
  },
};

const ServicesCommonPage = () => {
  const { pathname } = useLocation();
  const talendDataComponent = () => {
    return (
      <div className="talend-div">
        {talendData.map((item, index) => {
          return (
            <div key={index}>
              <h2>{item.name}</h2>
              <p>{item.description}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const applicationDevelopmentComponent = (value) => {
    const data = allServicesContent[value];
    return (
      <div className="application-development-div">
        <div className="heading-card">
          <h1>{data?.mainContent.heading}</h1>
          <p>{data?.mainContent.description}</p>
        </div>
        <div className="points-card">
          {data?.subContentHeading?.map((item, index) => {
            return (
              <div className="each-content-points" key={index}>
                <h3>{item?.heading}</h3>
                {item?.description && <p>{item?.description}</p>}
                <div className="all-points-card">
                  {item.points.map((point, index) => {
                    return (
                      <div key={index}>
                        <FaAngleRight className="angle-icon" />
                        <p>{point}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <p>{data?.paragraphOne}</p>
      </div>
    );
  };

  const salesforceDevelopmentComponent = (item) => {
    return (
      <div className="salesforce-development-div">
        <div className="heading-card">
          <h1>{item.mainHeading.heading}</h1>
          <p>{item.mainHeading.description}</p>
        </div>
        <div className="all-points-card">
          {item.points.map((item, index) => {
            return (
              <div key={index}>
                <FaAngleRight className="angle-icon" />
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className="sub-heading">
          <h3>{item.subHeading.heading}</h3>
          <p>{item.subHeading.description}</p>
        </div>
      </div>
    );
  };

  const staffingServiceComponent = (item) => {
    return (
      <div className="staffing-service-div">
        <div className="heading-card">
          <h1>{item.mainHeading.heading}</h1>
          <p>{item.mainHeading.description}</p>
        </div>
        <div className="sub-heading">
          <h3>{item.subHeading.heading}</h3>
          <p>{item.subHeading.description}</p>
        </div>
        <h4>{item.pointsObject.heading}</h4>
        <div className="all-staffing-points">
          {item.pointsObject.points.map((item, index) => {
            return (
              <div key={index} className="single-main-div">
                <h5>
                  {index + 1}. {item.mainPoint}
                </h5>
                {item.subPoints.map((item, index) => {
                  return (
                    <div key={index}>
                      <FaAngleRight className="angle-icon" />
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const returnComponent = () => {
    if (pathname.endsWith("it-services")) {
      return applicationDevelopmentComponent(0);
    } else if (pathname.endsWith("application-development")) {
      return applicationDevelopmentComponent(1);
    } else if (pathname.endsWith("big-data-development")) {
      return applicationDevelopmentComponent(2);
    } else if (pathname.endsWith("staffing-services")) {
      return staffingServiceComponent(staffing);
    } else if (pathname.endsWith("salesforce-development")) {
      return salesforceDevelopmentComponent(salesforceDevelopment);
    } else if (pathname.endsWith("talend-development")) {
      return talendDataComponent();
    }
  };

  return (
    <div className="service-content">
      {returnComponent()}
      <div className="right-div">
        <ServicesLinks />
      </div>
    </div>
  );
};

export default ServicesCommonPage;
