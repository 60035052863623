import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "bootstrap/dist/css/bootstrap.min.css";
import img1 from "./assets/banner-2 .jpg";
import img2 from "./assets/banner.jpg";
import imgs1 from "./assets/img1.jpg";
import imgs2 from "./assets/img3.jpg";
import imgs3 from "./assets/test-1.jpg";
import imgs4 from "./assets/test-2.jpg";
import imgs5 from "./assets/test-3.jpg";
import imgs6 from "./assets/test-5.jpg";
import imgs7 from "./assets/test-7.jpg";
import exp from "./assets/exp.jpg";
import lap from "./assets/lap.jpg";
import { IoIosGlobe } from "react-icons/io";

import { FaLaptopCode } from "react-icons/fa";
import { GrDatabase } from "react-icons/gr";
import { PiUsersThreeBold } from "react-icons/pi";
import { RiUserStarFill } from "react-icons/ri";
import { TbEyeSearch } from "react-icons/tb";

import "./home.scss";

const Home = () => {
  const images = [
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
  ];
  const carouselImages = [
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
    imgs1,
    imgs2,
    imgs3,
    imgs4,
    imgs5,
    imgs6,
    imgs7,
  ];
  const aboutSectionRef = useRef(null);
  const textSectionRef = useRef(null);
  const imgSectionRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = aboutSectionRef.current;
      const textSection = textSectionRef.current;
      const imgSection = imgSectionRef.current;
      const serviceIcons = document.querySelectorAll(".Text-icons .icons");

      if (aboutSection) {
        const sectionPosition = aboutSection.getBoundingClientRect().top;
        const screenPosition = (window.innerHeight / 5) * 4;

        if (sectionPosition < screenPosition) {
          aboutSection.classList.add("animate");
          textSection.classList.add("animate");
          imgSection.classList.add("animate");
        }
      }

      serviceIcons.forEach((icon, index) => {
        const iconPosition = icon.getBoundingClientRect().top;
        const screenPosition = window.innerHeight;

        if (iconPosition < screenPosition) {
          setTimeout(() => {
            icon.classList.add("animate");
          }, index * 200); // Delay each icon's animation by 200ms
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className="home-page">
      <div className="first-section">
        <Carousel
          showArrows={true}
          autoPlay
          infiniteLoop
          interval={5000}
          showThumbs={false}
          showIndicators={false}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="second-second">
        {/* <Carousel
          autoPlay
          infiniteLoop
          interval={2000}
          showThumbs={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={20} // Adjusts to show 5 slides at once
        >
          {carouselImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Carousel> */}
        <Carousel
          autoPlay
          infiniteLoop
          interval={2000}
          showThumbs={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={20}
          swipeable={false} // This will prevent users from manually swiping the carousel
          dynamicHeight={false} // This ensures the height of the carousel does not change
        >
          {carouselImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="about-section" ref={aboutSectionRef}>
        <div className="text-section" ref={textSectionRef}>
          <h2>ABOUT US</h2>
          <p>
            Glenysys Technologies IT Services are provided with innovative
            strategies that accelerate the way to do a business of any type that
            allows enabling technologies stimulating the business growth of your
            company. From accumulating ideas through our extensive research,
            analytics and clientele engagement to deliver IT Services and
            Solutions, we plan meticulously to offer customized, optimized and
            proficiency with comprehensive in advanced technologies as well as
            proven IT expertise in the entire IT space. At Glenysys
            Technologies, we strategically plan to cut the unwanted expenditure
            for our clients. This will help you in maximizing quality, improves
            performance, efficiency and procure valuable investments.
          </p>
          <button onClick={() => navigate("/about-us")}>Read More</button>
        </div>
        <div className="img-section" ref={imgSectionRef}>
          <img src={exp} alt="Experience" />
        </div>
      </div>
      <div className="service-section">
        <div className="service-main">
          <div className="img-container">
            <img src={lap} alt="Laptop" />
          </div>
          <div className="Text-container">
            <div className="Text-main">
              <h2>SERVICES</h2>
              <p>
                Helping you find the right IT Solutions For Services Beyond
                Technology
              </p>
            </div>
            <div className="Text-icons">
              <div
                className="icons"
                onClick={() => navigate("/services/it-services")}
              >
                <IoIosGlobe className="icon" />
                IT Services
              </div>
              <div
                className="icons"
                onClick={() => navigate("/services/application-development")}
              >
                <FaLaptopCode className="icon" />
                Application Development
              </div>
              <div
                className="icons"
                onClick={() => navigate("/services/big-data-development")}
              >
                <GrDatabase className="icon" />
                Big Data Development
              </div>
              <div
                className="icons"
                onClick={() => navigate("/services/staffing-services")}
              >
                <PiUsersThreeBold className="icon" />
                Staffing Services
              </div>
              <div
                className="icons"
                onClick={() => navigate("/services/salesforce-development")}
              >
                <RiUserStarFill className="icon" />
                Salesforce Development
              </div>
              <div
                className="icons"
                onClick={() => navigate("/services/talend-development")}
              >
                <TbEyeSearch className="icon" />
                Talend Development
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
