import "./headingImage.scss";
import mainImg from "../../assets/app-dev.jpg";

const HeadingImage = ({ name }) => {
  return (
    <div className="main-img-section">
      <img src={mainImg} />
      <div className="main-img-text">{name}</div>
    </div>
  );
};

export default HeadingImage;
