import React from "react";
import HeadingImage from "../../../global/headingImage/HeadingImage";
import "./talenddevelopment.scss";
import ServicesCommonPage from "../../../global/servicesCommonPage/ServicesCommonPage";

const TalendDevelopment = () => {
  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <HeadingImage name={"Talend Development"} />
      <ServicesCommonPage />
    </div>
  );
};

export default TalendDevelopment;
