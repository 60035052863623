import React from "react";
import HeadingImage from "../../../global/headingImage/HeadingImage";
import ServicesCommonPage from "../../../global/servicesCommonPage/ServicesCommonPage";
import "./iTservices.scss";

const ItServices = () => {
  return (
    <div className="it-services" style={{ width: "90%", margin: "auto" }}>
      <HeadingImage name={"IT Services"} />
      <ServicesCommonPage />
    </div>
  );
};

export default ItServices;
