import React from "react";
import "./servicesLinks.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import email from "../../assets/mail.png";
import { HiOutlineMailOpen } from "react-icons/hi";

const subLinks = [
  {
    name: "IT Services",
    path: "/services/it-services",
  },
  {
    name: "Application Development",
    path: "/services/application-development",
  },
  {
    name: "Big Data Development",
    path: "/services/big-data-development",
  },
  {
    name: "Staffing Services",
    path: "/services/staffing-services",
  },
  {
    name: "Salesforce Development",
    path: "/services/salesforce-development",
  },
  {
    name: "Talend Development",
    path: "/services/talend-development",
  },
];

const ServicesLinks = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="services-links">
      <div className="links-card">
        {subLinks.map((item, index) => {
          return (
            <div
              onClick={() => navigate(item.path)}
              className={`${pathname === item.path && "active-card"}`}
            >
              <FaAngleRight />
              <p key={index}>{item.name}</p>
            </div>
          );
        })}
      </div>
      <div className="email-card" onClick={() => navigate("/contact-us")}>
        <HiOutlineMailOpen className="email-image" />
        <h2>Email Enquiry</h2>
      </div>
    </div>
  );
};

export default ServicesLinks;
