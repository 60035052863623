import "./services.scss";
import { IoIosGlobe } from "react-icons/io";
import { FaLaptopCode } from "react-icons/fa";
import { GrDatabase } from "react-icons/gr";
import { PiUsersThreeBold } from "react-icons/pi";
import { RiUserStarFill } from "react-icons/ri";
import { TbEyeSearch } from "react-icons/tb";
import servicesImage from "../assets/lap.jpg";
import HeadingImage from "../global/headingImage/HeadingImage";
import { useNavigate } from "react-router-dom";

const serviceFeatures = [
  {
    name: "IT Services",
    img: <IoIosGlobe className="service-image" />,
    path: "/services/it-services",
  },
  {
    name: "Application Development",
    img: <FaLaptopCode className="service-image" />,
    path: "/services/application-development",
  },
  {
    name: "Big Data Development",
    img: <GrDatabase className="service-image" />,
    path: "/services/big-data-development",
  },
  {
    name: "Staffing Services",
    img: <PiUsersThreeBold className="service-image" />,
    path: "/services/staffing-services",
  },
  {
    name: "Salesforce Development",
    img: <RiUserStarFill className="service-image" />,
    path: "/services/salesforce-development",
  },
  {
    name: "Talend Development",
    img: <TbEyeSearch className="service-image" />,
    path: "/services/talend-development",
  },
];

const Services = () => {
  const navigate = useNavigate();
  return (
    <div className="services" style={{ width: "90%", margin: "auto" }}>
      <HeadingImage name={"Services"} />
      <div className="features-container">
        <div className="image-card">
          <img src={servicesImage} />
        </div>
        <div className="services-features-card">
          <div className="services-sub-heading">
            <h3>Services</h3>
            <p>
              Helping you find the right IT Solutions For Services Beyond
              Technology
            </p>
          </div>
          <div className="features-list">
            {serviceFeatures.map((item, index) => {
              return (
                <div key={index} onClick={() => navigate(item.path)}>
                  {item.img}
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
