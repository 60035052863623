import React from "react";
import "./bigDataDevelopment.scss";
import HeadingImage from "../../../global/headingImage/HeadingImage";
import ServicesCommonPage from "../../../global/servicesCommonPage/ServicesCommonPage";

const BigDataDevelopment = () => {
  return (
    <div
      className="big-data-development"
      style={{ width: "90%", margin: "auto" }}
    >
      <HeadingImage name="Big Data Development" />
      <ServicesCommonPage />
    </div>
  );
};

export default BigDataDevelopment;
